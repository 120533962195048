<template>
  <!--Customer Payments-->
  <v-sheet
    elevation="2"
    class="pa-5"
    v-if="value.transaction_allocation_status === 'Customer Payment'"
  >
    <customer-payment-allocation-summary :value="value">
    </customer-payment-allocation-summary>
  </v-sheet>
  <!--Inter Account Transfer Modal-->
  <v-sheet
    elevation="2"
    class="pa-5"
    v-else-if="value.transaction_allocation_status === 'Inter Account Transfer'"
  >
    <inter-account-transfer-summary :value="value">
    </inter-account-transfer-summary>
  </v-sheet>
  <!--General Journal Entry Modal-->
  <v-sheet
    elevation="2"
    class="pa-5"
    v-else-if="
      value.transaction_allocation_status ===
      `Journal Entry - ${value.journal_entry.journal_entry_number}`
    "
  >
    <general-journal-entry-summary :value="value">
    </general-journal-entry-summary>
  </v-sheet>
</template>
<script>
export default {
  name: "BankStatementsViewAllocatedTransactionModal",
  props: ["value"],
  components: {
    CustomerPaymentAllocationSummary: () =>
      import(
        /* webpackPrefetch: true */ "./CustomerPaymentAllocationSummary.vue"
      ),
    InterAccountTransferSummary: () =>
      import(/* webpackPrefetch: true */ "./InterAccountTransferSummary.vue"),
    GeneralJournalEntrySummary: () =>
      import(/* webpackPrefetch: true */ "./GeneralJournalEntrySummary.vue"),
  },
};
</script>
